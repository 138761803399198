import React, { useEffect } from 'react';
import { useApi } from '@axiom/ui';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { UserApi } from '../../api/user/user';
import { isClientUser } from '../../utils/roles';

export const ClientEngagementsRedirectClientUser = () => {
  const [{ data: user }] = useApi(UserApi.readUser());
  const navigate = useNavigate();
  const { accountId: urlAccountId } = useParams();

  useEffect(() => {
    if (!urlAccountId && isClientUser(user)) {
      const accountId = user?.contact?.accounts?.[0]?.id ?? null;

      if (accountId) {
        navigate(`./account/${accountId}`, { replace: true });
      }
    }
  });

  return (
    <div data-test="CONTAINER">
      <Outlet />
    </div>
  );
};
