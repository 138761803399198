import React from 'react';
import {
  Button,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
  Gutter,
  DarkTheme,
  GridRow,
  GridColumn,
  AxiomLogo,
  Grid,
  Flashy,
  LayoutBleed,
  Paragraph,
  ParagraphHeader,
  CondensedSmall,
  Layout,
} from '@axiom/ui';

import { FooterBlock, FooterSocialMedia } from './FooterStyles';

export const Footer = () => (
  <Gutter top="144px">
    <DarkTheme>
      <LayoutBleed>
        <FooterBlock>
          <Grid>
            <GridRow gutterBottom="8px">
              <GridColumn>
                <Flashy color="textReversed">
                  <AxiomLogo width="200" height="75" />
                </Flashy>
              </GridColumn>
            </GridRow>
            <GridRow gutterBottom="16px">
              <GridColumn largeScreenWidth="4" smallScreenWidth="12">
                <ParagraphHeader>Contact</ParagraphHeader>
                <Gutter bottom="8px" />
                <Paragraph>
                  Have any questions, ideas or want to work together?
                </Paragraph>
                <Gutter bottom="8px" />
                <Button
                  name="axiomPortalContactUsButton"
                  to="mailto:answers@axiomlaw.com"
                >
                  Contact Us
                </Button>
                <Gutter bottom="8px" />
              </GridColumn>
              <GridColumn largeScreenWidth="2" smallScreenWidth="12">
                <ParagraphHeader>Company</ParagraphHeader>
                <a href="https://www.axiomlaw.com/about-us/our-story">
                  About Us
                </a>
                <br />
                <a href="https://www.axiomlaw.com/about-us/global-reach">
                  Locations
                </a>
                <Gutter bottom="8px" />
              </GridColumn>
              <GridColumn largeScreenWidth="2" smallScreenWidth="12">
                <ParagraphHeader>Solutions</ParagraphHeader>
                <a href="https://www.axiomlaw.com/solutions/what-we-do">
                  Talent Platform
                </a>
                <br />
                <a href="https://www.axiomlaw.com/solutions/data-privacy">
                  Data Privacy
                </a>
                <br />
                <a href="https://www.axiomlaw.com/solutions/contract-negotiations">
                  Contract Negotiations
                </a>
                <Gutter bottom="8px" />
              </GridColumn>
              <GridColumn largeScreenWidth={2} smallScreenWidth={12}>
                <ParagraphHeader>Careers</ParagraphHeader>
                <a href="https://www.axiomlaw.com/careers/headquarters">
                  Headquarters
                </a>
                <br />
                <a href="https://www.axiomlaw.com/careers/lawyers">Lawyers</a>
                <Gutter bottom="8px" />
              </GridColumn>
              <GridColumn largeScreenWidth={2} smallScreenWidth={12}>
                <ParagraphHeader>Resources</ParagraphHeader>
                <a href="https://www.axiomlaw.com/blog">Blog</a>
                <br />
                <a href="https://www.axiomlaw.com/resources/press-releases">
                  Press Releases
                </a>
                <br />
                <a href="https://www.axiomlaw.com/resources/events">Events</a>
                <br />
                <a href="https://www.axiomlaw.com/clearing-the-path">
                  White Paper
                </a>
                <br />
                <a href="https://www.axiomlaw.com/resources/newsroom">
                  Newsroom
                </a>
                <Gutter bottom="8px" />
              </GridColumn>
            </GridRow>
            <GridRow gutterBottom="16px">
              <GridColumn largeScreenWidth={5} smallScreenWidth={12}>
                <Layout position="middle" horizontalGutter="8px">
                  <CondensedSmall>
                    &copy; Axiom {new Date().getFullYear()}
                  </CondensedSmall>
                  <CondensedSmall>
                    <a
                      href="https://www.axiomlaw.com/privacy-notice"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Notice
                    </a>
                  </CondensedSmall>
                  <CondensedSmall>
                    <a
                      href="https://www.axiomlaw.com/axiom-terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>
                  </CondensedSmall>
                  <CondensedSmall>
                    All logos provided by:{' '}
                    <a
                      rel="noopener noreferrer"
                      href="https://clearbit.com/logo"
                      target="_blank"
                    >
                      Clearbit.com
                    </a>
                  </CondensedSmall>
                </Layout>
                <Gutter bottom="8px" />
              </GridColumn>
              <GridColumn
                largeScreenWidth={4}
                smallScreenWidth={12}
                textAlign="center"
              >
                {/* OneTrust Cookies Settings button start */}
                <Button
                  pattern="secondary"
                  variation="outline"
                  id="ot-sdk-btn"
                  className="ot-sdk-show-settings"
                >
                  Cookie Settings
                </Button>
                {/* OneTrust Cookies Settings button end */}
                <Gutter bottom="8px" />
              </GridColumn>
              <GridColumn
                largeScreenWidth={4}
                smallScreenWidth={12}
                textAlign="right"
              >
                <FooterSocialMedia>
                  <a href="https://www.linkedin.com/company/axiom_law">
                    <LinkedInIcon />
                    <span className="sr-only">LinkedIn</span>
                  </a>
                </FooterSocialMedia>
                <FooterSocialMedia>
                  <a href="https://twitter.com/axiom_law">
                    <TwitterIcon />
                    <span className="sr-only">Twitter</span>
                  </a>
                </FooterSocialMedia>
                <FooterSocialMedia>
                  <a href="https://www.facebook.com/AxiomLaw/">
                    <FacebookIcon />
                    <span className="sr-only">Facebook</span>
                  </a>
                </FooterSocialMedia>
                <FooterSocialMedia>
                  <a href="https://www.instagram.com/axiom_law/">
                    <InstagramIcon />
                    <span className="sr-only">Instagram</span>
                  </a>
                </FooterSocialMedia>
              </GridColumn>
            </GridRow>
          </Grid>
        </FooterBlock>
      </LayoutBleed>
    </DarkTheme>
  </Gutter>
);
