import { Api } from '@axiom/ui';
import { InstantMatch, Opportunity } from '@axiom/validation';

import { EnvUtil } from '../../utils/env-util';

class InstantMatchesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
    });
  }

  readInstantMatches(opportunityId: Opportunity['id']) {
    return super.read<{ data: Array<InstantMatch> }>({
      endpoint: `/instant/matches/opportunities/${opportunityId}`,
      method: 'GET',
    });
  }
}

export const InstantMatchesApi = new InstantMatchesApiClass();
