import React from 'react';
import {
  Button,
  Checkbox,
  Form,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Layout,
  LayoutItem,
  ImageCircle,
  CandidateProfileUtil,
  FormGroup,
  Gutter,
} from '@axiom/ui';
import { z } from 'zod';
import { Candidate } from '@axiom/validation';
import { PossibleImageSizes } from '@axiom/const';

export interface RateConfirmModalProps {
  onConfirm: () => void;
  onClose: (arg0: boolean) => void;
  candidate: Candidate;
}

const ConfidentialRateConfirmSchema = z.object({
  RATE_CONFIRMED: z.string(),
});

export const ClientEngagementsConfidentialRateConfirmModal = ({
  onConfirm,
  onClose,
  candidate,
}: RateConfirmModalProps) => {
  return (
    <Form
      name="CONFIDENTIAL_RATE_CONFIRM_MODAL_FORM"
      schema={ConfidentialRateConfirmSchema}
      initialValues={{
        RATE_CONFIRMED: null,
      }}
      onSubmit={onConfirm}
    >
      {({ fireSubmit }) => {
        return (
          <Modal size="default" name="CONFIDENTIAL_RATE_CONFIRM_MODAL">
            <ModalHeader
              onClose={() => onClose(false)}
              name="CONFIDENTIAL_RATE_CONFIRM_MODAL_HEADER"
            >
              <Layout position="middle" wrap>
                <LayoutItem rightGutter="16px">
                  <ImageCircle
                    imageName={candidate.calculatedDisplayName}
                    src={CandidateProfileUtil.getProfileImageUri(
                      candidate,
                      PossibleImageSizes.W_100
                    )}
                    size="small"
                    name="TALENT_IMAGE"
                  />
                </LayoutItem>
                <LayoutItem fluid name="HEADER_TEXT">
                  {`Interview ${candidate.calculatedFirstName}`}
                </LayoutItem>
              </Layout>
            </ModalHeader>
            <ModalSection>
              <FormGroup
                name="RATE_CONFIRMED"
                label="Confidential rate agreement"
                description={`
                The Axiom rate that you received is confidential Axiom information and may not be shared with legal talent. 
                Our talent agree on their compensation when joining Axiom and are not able to modify or negotiate the rate shared with you. 
                Please do not discuss rates directly with Axiom talent.`}
              >
                <Gutter bottom="16px" />
                <Checkbox
                  name="RATE_CONFIRMED"
                  option="agree"
                  displayValue="I agree not to discuss rates directly with Axiom talent."
                />
              </FormGroup>
              <Gutter bottom="16px" />
            </ModalSection>
            <ModalFooter>
              <Button
                name="CANCEL_BUTTON"
                variation="outline"
                onClick={() => onClose(false)}
              >
                Cancel
              </Button>
              <Button name="CONFIRM_RATE_BUTTON" onClick={fireSubmit}>
                Next
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
