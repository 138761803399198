import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TalentBios, useApi } from '@axiom/ui';

import { PracticeAreaApi } from '../../api/practiceAreas';
import { AccountSubmissionsLegacyApi } from '../../api/account/account-submissions-legacy';
import { CandidateApi } from '../../api/candidate/candidate';
import { EnvUtil } from '../../utils/env-util';

export const CandidateBios = () => {
  const urlParams = useParams();
  const location = useLocation();
  const { accountId, submissionId, candidateId } = urlParams;
  const isAnon = location.pathname.includes('/anonymous');
  const download = location.pathname.includes('/download');

  const [{ data: candidate }, { data: practiceAreas }] = useApi(
    AccountSubmissionsLegacyApi.readCandidateAccountSubmissionDetails(
      accountId,
      submissionId,
      candidateId
    ),
    PracticeAreaApi.readPracticeAreas()
  );

  const [{ data: groupedExperiences } = { data: [] }] = useApi(
    candidate.id && CandidateApi.readCandidateGroupedExperiences(candidate.id)
  );

  const baseUrl = `/my-axiom/${accountId}/${submissionId}/${candidate.id}`;

  const handleOnNavigate = () => {
    const href = `${baseUrl}/download/bio${isAnon ? '/anonymous' : ''}`;

    window.open(href, 'download_bio');
  };

  return (
    <TalentBios
      candidate={candidate}
      groupedExperiences={groupedExperiences}
      practiceAreas={practiceAreas}
      onNavigate={handleOnNavigate}
      clientUrl={EnvUtil.clientEngagementsUrl}
      isAnonymous={!!isAnon}
      download={!!download}
    />
  );
};
