import { Api, ExternalSearchRequestDto } from '@axiom/ui';
import {
  Candidate,
  GroupedExperiences,
  CandidateWorkFeedSearch,
} from '@axiom/validation';

import ApiHelper from '../../lib/api-helper';
import { EnvUtil } from '../../utils/env-util';
import { SearchFilters, SearchFiltersMeta } from '../../models/search-filters';
import { WindowUtil } from '../../utils/window-util';

const api = new ApiHelper('candidates');

class CandidateApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readCandidateGroupedExperiences(
    candidateId: Candidate['id'],
    query?: { submissionId: string; isInstantMatch?: boolean }
  ) {
    return super.read<{ data: GroupedExperiences[] }>({
      endpoint: `/candidates/${candidateId}/groupedExperiences`,
      query,
      method: 'GET',
    });
  }

  readExternalCandidate = async (id: string) => {
    try {
      const { data } = await api.GET(
        `${EnvUtil.apiAltUrl}/external/candidates/${id}`
      );

      return data;
    } catch (ex) {
      /**
       * Based on CR-2386: If a client is unauthed and trying to view an SPV page not visible in ALT, the api will return 403,
       * The desired behavior is redirecting client to the login page with the current SPV page as a RelayState.
       */
      if (ex?.applicationError?.response?.status === 403) {
        WindowUtil.toLogin();

        return null;
      }

      throw ex;
    }
  };

  readCandidateFilters() {
    return super.write<{ data: SearchFilters }>({
      endpoint: '/candidates/search/filters',
      method: 'GET',
    });
  }

  readCandidateStats(body: {
    stats: {
      id: string;
      type: string;
      filters: SearchFilters;
    }[];
  }) {
    return super.write<{ data: { count: number; id: string }[] }>({
      endpoint: '/candidates/stats',
      method: 'POST',
      encodeQuery: false,
      body,
    });
  }

  readCandidateSearchResults(filterMeta: SearchFiltersMeta) {
    const requestBody = new ExternalSearchRequestDto(filterMeta).export();

    return super.write<{
      data: Candidate;
      meta: CandidateWorkFeedSearch;
    }>({
      endpoint: '/candidates/search',
      method: 'POST',
      body: requestBody,
    });
  }

  readCandidateSearchResultsForDashboard(filterMeta: {
    page: number;
    search?: string;
    sort?: string;
    filters: SearchFilters;
    pageSize?: number;
  }) {
    const requestBody = new ExternalSearchRequestDto(filterMeta).export();

    return super.read<{
      data: Candidate[];
    }>({
      endpoint: '/candidates/search',
      method: 'POST',
      body: requestBody,
    });
  }

  readCandidatesSimilarTo(id: Candidate['id']) {
    return super.read<{ data: { count: number; id: string }[] }>({
      endpoint: `/candidates/${id}/similarTo`,
      method: 'GET',
      query: {
        pageSize: 9,
        pageNumber: 1,
      },
    });
  }

  refreshCandidate(candidateId: Candidate['id']) {
    return super.invalidate(`/candidates/${candidateId}`);
  }
}

export const CandidateApi = new CandidateApiClass();
