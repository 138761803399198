import React from 'react';
import { HeaderBarLink } from '@axiom/ui';
import { Link, useLocation } from 'react-router-dom';

export const AuthenticatedHeaderMenuItem = ({
  children,
  name,
  target,
  url,
  secondaryMatchSlug,
}: {
  children: React.ReactNode;
  name: string;
  target?: string;
  url: string;
  secondaryMatchSlug?: string; // used for app level child pages
}) => {
  const location = useLocation();
  // the zero index is an empty string
  const urlBits = url.split('?')[0].split('/');
  const pathnameBits = location.pathname.split('/');
  let matches = pathnameBits[1] === urlBits[1];

  if (secondaryMatchSlug) {
    matches = pathnameBits.at(-1) === secondaryMatchSlug;
  }

  return (
    <HeaderBarLink selected={matches} name={name}>
      <Link to={url} target={target}>
        {children}
      </Link>
    </HeaderBarLink>
  );
};
