import React, { useState } from 'react';
import {
  Banner,
  Button,
  CondensedLarge,
  ParagraphHeader,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Input,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Textarea,
  Radio,
  Layout,
  ImageCircle,
  IconButton,
  Dropdown,
  ToastUtil,
  CondensedHeader,
} from '@axiom/ui';
import { z } from 'zod';
import { SchemaEmail } from '@axiom/types';
import { Account, AfcSubmission } from '@axiom/validation';
import { ContactsOpportunitiesConst } from '@axiom/const';

import { AccountLegacyApi } from '../../api/account/account-legacy';
import { OpportunityApi } from '../../api/opportunity/opportunity';
import { AccountSubmissionsApi } from '../../api/account/account-submissions';

import { ClientEngagementsRemoveContactModal } from './ClientEngagementsRemoveContactModal';

/**
 * Here we check to make sure that the given name string is valid.
 * This given name should contain more than just spaces and the result is trimmed
 */
export const SchemaName: z.ZodEffects<z.ZodString, string, string> = z
  .string()
  .trim()
  .refine(val => val?.trim().length > 0, {
    message: 'Required',
  });

const formSchema = z.object({
  firstName: SchemaName,
  lastName: SchemaName,
  email: SchemaEmail.nullable(),
  notes: z.string().max(1000).nullable(),
  isUnitedStates: z.string().refine(val => val === 'Yes', {
    message: 'Please remove the selected recipient.',
  }),
});

const confidentialFormSchema = z.object({
  confidentialOption: z.boolean().nullable(),
});

export const ClientEngagementsShareModal = ({
  accountId,
  currentSubmission,
  onClose,
  accountName,
}: {
  accountId: Account['id'];
  accountName: Account['calculatedName'];
  currentSubmission: AfcSubmission;
  onClose: () => void;
}) => {
  const {
    confidential,
    contacts,
    id: opportunityId,
    submissionId,
  } = currentSubmission;

  const [showRemoveContactModal, setShowRemoveContactModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(contacts[0]);
  const defaultNote =
    'Visit Axiom using the button below to view talent profiles for this engagement. You’ll be able to see all of our most up-to-date matches, as well as take action on interviewing or hiring talent.';

  const closeRemoveUserModal = () => {
    setShowRemoveContactModal(false);
  };

  const loadToast = (name: string) => {
    ToastUtil.add({
      name: `ENGAGEMENT_SHARED`,
      type: 'info',
      children: (
        <>
          <CondensedHeader>Engagement Shared</CondensedHeader>
          <CondensedLarge>
            We've sent {name} an email with a link to sign in and view this
            engagement.
          </CondensedLarge>
        </>
      ),
    });
  };

  const handleShareUserSubmit = async (
    formData: z.infer<typeof formSchema>,
    resetForm: () => void
  ) => {
    const body = {
      contacts: [
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
        },
      ],
      message: formData.notes === undefined ? defaultNote : formData.notes,
      submissionId,
    };

    await AccountLegacyApi.createShareAccount(accountId, body);
    await AccountSubmissionsApi.refreshAccountSubmissionDetails(
      accountId,
      submissionId,
      { ctaFilters: ['None', 'Hire'] }
    );
    await AccountSubmissionsApi.readAccountSubmissionDetails(
      accountId,
      submissionId,
      { ctaFilters: ['None', 'Hire'] }
    );
    loadToast(body.contacts[0].firstName);
    resetForm();
  };

  const handleConfidentialitySubmit = async (
    changedData: z.infer<typeof confidentialFormSchema>
  ) => {
    const keys = Object.keys(changedData);
    if (keys.length === 0 || changedData.confidentialOption === confidential) {
      return;
    }
    await OpportunityApi.updateConfidentiality(opportunityId, {
      confidential: changedData.confidentialOption,
    });
    await AccountSubmissionsApi.refreshAccountSubmissionDetails(
      accountId,
      submissionId,
      { ctaFilters: ['None', 'Hire'] }
    );
  };

  return (
    <>
      {showRemoveContactModal && (
        <ClientEngagementsRemoveContactModal
          accountId={accountId}
          opportunityId={opportunityId}
          submissionId={submissionId}
          contactId={selectedContact?.id}
          name={selectedContact?.fullName}
          onClose={closeRemoveUserModal}
        />
      )}
      {!showRemoveContactModal && (
        <Form
          name="SHARE_FORM"
          schema={formSchema}
          onSubmit={(formData, actions) =>
            handleShareUserSubmit(formData, actions.resetForm)
          }
          initialValues={{
            firstName: null,
            lastName: null,
            email: null,
            notes: defaultNote,
            isUnitedStates: null,
          }}
        >
          {({ fireSubmit, fireReset, values }) => {
            const showShareForm =
              !!values.email && values.isUnitedStates === 'Yes';
            return (
              <Modal name="SHARE_ACCOUNT_MODAL">
                <ModalHeader
                  name="SHARE_ACCOUNT_MODAL_HEADER"
                  onClose={onClose}
                >
                  Sharing and Confidentiality
                </ModalHeader>
                <ModalSection>
                  {!showShareForm && (
                    <Gutter bottom="16px" name="CONFIDENTIALITY_FORM">
                      <ParagraphHeader>
                        Who can view this engagement
                      </ParagraphHeader>
                      <Gutter bottom="16px" />
                      <Form
                        name="CONFIDENTIAL_FORM"
                        schema={confidentialFormSchema}
                        onSubmit={async changedData =>
                          handleConfidentialitySubmit(changedData)
                        }
                        initialValues={{
                          confidentialOption: confidential,
                        }}
                      >
                        {({ fireSubmit: fireConfidentialSubmit }) => (
                          <Layout direction="vertical" verticalGutter="16px">
                            <Radio
                              name="confidentialOption"
                              displayValue={`Everyone at ${accountName} with an Axiom account`}
                              option={false}
                              onChange={() => {
                                fireConfidentialSubmit();
                              }}
                            />
                            <Radio
                              name="confidentialOption"
                              displayValue="Only colleagues you invite (confidential)"
                              option
                              onChange={() => {
                                fireConfidentialSubmit();
                              }}
                            />
                          </Layout>
                        )}
                      </Form>
                      <hr />
                    </Gutter>
                  )}
                  <Banner impact="high">
                    Axiom cannot send communications to recipients outside of
                    the United States unless they opt in and share their email
                    with us.
                  </Banner>
                  <Gutter bottom="16px" />
                  <Grid>
                    <ParagraphHeader>Invite colleagues</ParagraphHeader>

                    <GridRow gutterVertical="16px">
                      <GridColumn width={7}>
                        <Input name="email" label="Recipient's Email" />
                        <Gutter bottom="8px" only="mobile" />
                      </GridColumn>
                      <GridColumn width={5}>
                        <Dropdown
                          label="Located in the United States?"
                          options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ]}
                          displayKey="label"
                          valueKey="value"
                          name="isUnitedStates"
                        />
                      </GridColumn>
                    </GridRow>
                    {showShareForm && (
                      <GridRow
                        name="SHARE_FORM_NAME_INPUTS"
                        gutterVertical="16px"
                      >
                        <GridColumn
                          largeScreenWidth={6}
                          tabletWidth={6}
                          mobileWidth={12}
                        >
                          <Input name="firstName" label="First Name" />
                          <Gutter bottom="8px" only="mobile" />
                        </GridColumn>
                        <GridColumn
                          largeScreenWidth={6}
                          tabletWidth={6}
                          mobileWidth={12}
                        >
                          <Input name="lastName" label="Last Name" />
                          <Gutter bottom="8px" only="mobile" />
                        </GridColumn>
                      </GridRow>
                    )}
                  </Grid>

                  {!showShareForm && (
                    <>
                      <hr />
                      <ParagraphHeader name="CONFIDENTIALITY_CONTACTS_HEADER">
                        Invited colleagues at {accountName} who can see this
                        engagement
                      </ParagraphHeader>
                      <Gutter bottom="16px" />
                      <Grid>
                        {contacts?.map(contact => (
                          <GridRow
                            name="CONFIDENTIAL_CONTACT"
                            gutterBottom="16px"
                            key={contact.id}
                            verticalAlign="middle"
                          >
                            <GridColumn
                              largeScreenWidth={9}
                              tabletWidth={9}
                              mobileWidth={8}
                            >
                              <Layout horizontalGutter="8px">
                                <div>
                                  <ImageCircle
                                    size="thumbnail"
                                    imageName={contact.fullName}
                                  />{' '}
                                </div>

                                <CondensedLarge
                                  name="CONTACT_OPP_NAME"
                                  maxLines={1}
                                >
                                  {contact.fullName}
                                </CondensedLarge>
                              </Layout>
                            </GridColumn>
                            <GridColumn
                              largeScreenWidth={3}
                              tabletWidth={3}
                              mobileWidth={4}
                              textAlign="right"
                            >
                              {contact.displayRole ===
                                ContactsOpportunitiesConst.Roles.Owner && (
                                <CondensedLarge name="OWNER_ROLE">
                                  Primary Contact
                                </CondensedLarge>
                              )}
                              {contact.displayRole ===
                                ContactsOpportunitiesConst.Roles.None &&
                                confidential && (
                                  <IconButton
                                    name="CONTACT_OPP_DELETE_ICON"
                                    onClick={() => {
                                      setSelectedContact(contact);
                                      setShowRemoveContactModal(true);
                                    }}
                                    type="button"
                                    icon="trash"
                                    pattern="secondary"
                                    variation="minimal"
                                  />
                                )}
                            </GridColumn>
                          </GridRow>
                        ))}
                      </Grid>
                    </>
                  )}
                  {showShareForm && (
                    <Gutter bottom="16px">
                      <Textarea
                        label="Let them know why you are sharing this engagement"
                        name="notes"
                      />
                    </Gutter>
                  )}
                </ModalSection>
                {showShareForm ? (
                  <ModalFooter name="SHARE_MODAL_FOOTER">
                    <Button
                      name="CANCEL"
                      variation="outline"
                      onClick={() => {
                        fireReset();
                      }}
                    >
                      Back
                    </Button>
                    <Button name="SUBMIT" onClick={fireSubmit}>
                      Share
                    </Button>
                  </ModalFooter>
                ) : (
                  <ModalFooter name="CONFIDENTIAL_MODAL_FOOTER">
                    <Button name="CLOSE" onClick={onClose}>
                      Close
                    </Button>
                  </ModalFooter>
                )}
              </Modal>
            );
          }}
        </Form>
      )}
    </>
  );
};
