import { Api } from '@axiom/ui';
import { Candidate, Submission, SubmissionCandidate } from '@axiom/validation';

import ApiHelper from '../../lib/api-helper';
import { EnvUtil } from '../../utils/env-util';

const api = new ApiHelper('ACCOUNTS_SUBMISSIONS');

export const getAccountSubmissions = async (accountId: string) => {
  const { data } = await api.GET(`/accounts/${accountId}/submissions`);
  return data;
};

class AccountSubmissionsLegacyApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readAccountSubmissions(accountId: Submission['accountId']) {
    return super.read<{ data: Submission[] }>({
      endpoint: `/accounts/${accountId}/submissions`,
      method: 'GET',
    });
  }

  readCandidateAccountSubmissionDetails(
    accountId: Submission['accountId'],
    submissionId: Submission['id'],
    candidateId: Candidate['id'],
    isInstantMatch?: boolean
  ) {
    return super.read<{ data: SubmissionCandidate }>({
      query: { isInstantMatch: !!isInstantMatch },
      endpoint: `/accounts/${accountId}/submissions/${submissionId}/candidates/${candidateId}`,
      method: 'GET',
    });
  }

  refreshCandidateAccountSubmissionDetails(
    accountId: Submission['accountId'],
    submissionId: Submission['id'],
    candidateId: Candidate['id']
  ) {
    return super.invalidate(
      `/accounts/${accountId}/submissions/${submissionId}/candidates/${candidateId}`
    );
  }
}

export const AccountSubmissionsLegacyApi =
  new AccountSubmissionsLegacyApiClass();
