import React from 'react';
import {
  CandidateOpportunitiesConst,
  CurrencyCodeKeyTypes,
  CandidateImageConst,
  PositionsConst,
} from '@axiom/const';
import {
  CandidateRateUtil,
  CondensedHeader,
  CondensedLabel,
  CondensedMedium,
  Gutter,
  ImageCircle,
  Layout,
  LayoutItem,
  Visible,
} from '@axiom/ui';
import { Submission, CandidateOpportunity, Candidate } from '@axiom/validation';
import { CurrencyUtil } from '@axiom/utils';
import { CandidateYearsOfExperienceModel } from '@axiom/ui-models';

import { CandidateUtil } from '../../utils/candidate-util';

const { PossibleImageSizes } = CandidateImageConst;
const { ReservedType } = PositionsConst;

export const ClientEngagementsEngagementTalentTile: React.FC<{
  candidateOpp: CandidateOpportunity;
  submission: Submission;
  candidate: Candidate;
}> = ({ candidateOpp, submission, candidate }) => {
  const position = submission.positions.find(pos =>
    pos.candidateOpportunities?.find(co => co.id === candidateOpp.id)
  );
  const isInstantMatch = position
    ? position.candidateOpportunities?.find(co => co.id === candidateOpp.id)
        .isInstantMatch
    : undefined;

  const getReservedHoursCopy = () => {
    if (position?.reservedType !== ReservedType.Reserved) {
      return 'Pay as you go';
    }
    return `${position?.billingHoursPerWeek ?? 0} ${position?.billingHoursPerWeek === 1 ? 'hour' : 'hours'} reserved`;
  };

  const getRateCopy = () => {
    if (
      candidateOpp.displayBillingRate &&
      candidateOpp.displayBillingRate !==
        CandidateOpportunitiesConst.DisplayBillingRate.none
    ) {
      return `${CandidateRateUtil.formatCandidateRate(
        { ...candidateOpp, id: candidateOpp.candidate.id },
        CurrencyUtil.convertToSymbol(
          submission.currency as CurrencyCodeKeyTypes
        ),
        submission
      )}${isInstantMatch ? ` (${getReservedHoursCopy()})` : ''}`;
    }
    return '--';
  };

  return (
    <Layout horizontalGutter="8px" name="talent-tile">
      <LayoutItem fluid>
        <Layout position="middle" horizontalGutter="8px" wrap>
          <Visible only="largeScreen mobile">
            <LayoutItem>
              <ImageCircle
                size="small"
                imageName={candidate.calculatedDisplayName}
                src={CandidateUtil.getProfileImageUri(
                  candidate,
                  PossibleImageSizes.W_100
                )}
              />
            </LayoutItem>
          </Visible>
          <LayoutItem fluid hug>
            <CondensedHeader name="talent-name" maxLines={1}>
              {candidate.calculatedDisplayName}
            </CondensedHeader>

            <Gutter bottom="8px" />

            <Layout position="middle">
              <div className="max-lines-1">
                <CondensedLabel>Experience</CondensedLabel>
                <Gutter right="8px" />
                <CondensedMedium name="years-of-experience">
                  {candidate?.yearsOfExperience
                    ? `${new CandidateYearsOfExperienceModel(
                        candidate.yearsOfExperience
                      ).getYearsOfExperienceRange()} years`
                    : '--'}
                </CondensedMedium>
              </div>
            </Layout>

            <Layout position="middle">
              <div className="max-lines-1">
                <CondensedLabel>Rate</CondensedLabel>
                <Gutter right="8px" />
                <CondensedMedium name="rate">{getRateCopy()}</CondensedMedium>
              </div>
            </Layout>
          </LayoutItem>
        </Layout>
      </LayoutItem>
    </Layout>
  );
};
