import { useApi } from '@axiom/ui';
import { useParams } from 'react-router-dom';

import { AccountSubmissionsLegacyApi } from '../../api/account/account-submissions-legacy';

interface Args {
  accountId: string;
  submissionId: string;
  candidateId: string;
  isInstantMatch?: boolean;
}
export const useClientEngagementsCandidateSubmissionData = ({
  accountId,
  submissionId,
  candidateId,
  isInstantMatch,
}: Args) => {
  const {
    talentSlug: urlCandidateId,
    submissionId: urlSubmissionId,
    accountId: urlAccountId,
  } = useParams();
  const [{ data: currentCandidateSubmission }] = useApi(
    AccountSubmissionsLegacyApi.readCandidateAccountSubmissionDetails(
      accountId ?? urlAccountId,
      submissionId ?? urlSubmissionId,
      candidateId ?? urlCandidateId,
      isInstantMatch
    )
  );

  return {
    currentCandidateSubmission,
  };
};
